import {createSetTitleCallbackApp, createShareLinkCallbackApp, sendCallback} from "../../../assets/js/app-callbacks";

class VeranstaltungListenelement extends HTMLElement {

  connectedCallback() {
    this.headOnly = this.dataset.headOnly;
    if (this.headOnly) {
      return;
    }
    this.setElements();
    this.bindEvents();
    this.calculateInitialButtonMoreState();
    if (this.sindAppCallbacksAktiv) {
      this.appCallbackSetTitle(this, this.dataset.titel);
    }
  }

  setElements() {
    this.textElement = this.querySelectorAll(".item-text");
    this.weiterlesenButtonElement = this.querySelectorAll(".item-text-button");
    this.teilenButtonElement = this.querySelector(".teilen-button");
    this.copyMessageElement = this.querySelector(".copy-message");
    if (this.teilenButtonElement != null) {
      this.zielUrl = this.teilenButtonElement.dataset.link;
    }
  }

  bindEvents() {
    window.addEventListener('resize', () => this.calculateInitialButtonMoreState());
    this.weiterlesenButtonElement.forEach((button) => {
      button.addEventListener("click", (event) => {
        this.toggleText(event);
      });
    });
    this.teilenButtonElement.addEventListener("click", () => {
      this.linkTeilen();
    });
  }

  toggleText(event) {
    event.target.setAttribute("data-state", event.target.dataset.state === "more" ? "less" : "more");
    event.target.innerHTML = event.target.dataset.state === "more" ? "…weiterlesen" : "…weniger";
    event.target.previousSibling.previousSibling.setAttribute("data-state", event.target.previousSibling.previousSibling.dataset.state === "more" ? "less" : "more");
  }

  calculateInitialButtonMoreState() {
    this.weiterlesenButtonElement.forEach((button, index) => {
      const textElementHeight = this.textElement.item(index);
      button.style.display = (textElementHeight.scrollHeight > textElementHeight.offsetHeight) ? 'block' : 'none';
    });
  }

  async linkTeilen() {
    try {
      this.appCallbackShareButton(this, this.dataset.titel, this.getShareUrl().toString());
      if (navigator.share) {
        await this.teilenMitShareAPI();
      } else {
        if (!this.sindAppCallbacksAktiv) {
          await this.linkInZwischenablage();
          this.showCopiedMessageForXSeconds(2);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async teilenMitShareAPI() {
    const locationHostname = this.getLocationHostnameWithoutWWW();
    const title = `Meine Veranstaltung auf ${locationHostname}`;
    const text = `Schau mal hier, meine Veranstaltung auf ${locationHostname}`;
    try {
      const sharedata = {
        title: title,
        text: text,
        url: this.getShareUrl().toString()
      }
      await navigator.share(sharedata);
    } catch (error) {
      console.info(error);
    }
  }

  async linkInZwischenablage() {
    const url = this.getShareUrl().toString();
    await navigator.clipboard.writeText(url)
  }

  getLocationHostnameWithoutWWW() {
    const hostname = window.location.hostname;
    const hasWWW = hostname.indexOf("www") !== -1;
    if (hasWWW) {
      return hostname.replace("www.", "");
    }
    return hostname;
  }

  getShareUrl() {
    return new URL(window.location.origin + this.zielUrl);
  }
  
  get sindAppCallbacksAktiv() {
    return this.dataset.appCallbacksActive === 'true';
  }

  appCallbackSetTitle(parent, title) {
    if (!document.querySelector('[callback=setTitle]')) {
      sendCallback(createSetTitleCallbackApp('setTitle', title), parent);
    }
  }

  appCallbackShareButton(parent, text, url) {
    sendCallback(createShareLinkCallbackApp('share', text, url), parent);
  }

  showCopiedMessageForXSeconds(seconds) {
    this.copyMessageElement.removeAttribute("hidden");
    setTimeout(() => {
      this.copyMessageElement.setAttribute("hidden", true);
    },seconds * 1000);

  }
}

if (!customElements.get("veranstaltung-listenelement")) {
  customElements.define("veranstaltung-listenelement", VeranstaltungListenelement);
}
