export {
  createSetTitleCallbackApp,
  createShareLinkCallbackApp,
  sendCallback
};

function createSetTitleCallbackApp(action, title) {
  const callbackApp = document.createElement('callback-app');
  callbackApp.setAttribute('callback', action);
  if (title) {
    const callbackParam = document.createElement('callback-param');
    callbackParam.setAttribute('key', 'title');
    callbackParam.setAttribute('value', title);
    callbackApp.appendChild(callbackParam);
  }
  return callbackApp;
}

function createShareLinkCallbackApp(action, text, url) {
  const callbackApp = document.createElement('callback-app');
  callbackApp.setAttribute('callback', action);
  if (text) {
    const callbackParam1 = document.createElement('callback-param');
    callbackParam1.setAttribute('key', 'text');
    callbackParam1.setAttribute('value', text);
    callbackApp.appendChild(callbackParam1);
  }
  if (url) {
    const callbackParam2 = document.createElement('callback-param');
    callbackParam2.setAttribute('key', 'url');
    callbackParam2.setAttribute('value', url);
    callbackApp.appendChild(callbackParam2);
  }
  return callbackApp;
}

function sendCallback(element, parent) {
  parent.insertAdjacentElement("afterend", element);
}
